.add-icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.add-icon {
  font-size: 35px;
  cursor: pointer;
}
