@font-face {
	font-family: 'GothamRoundedBook';
	src: url('../../assets/fonts/GothamRoundedBook.otf');
}
.modal-header{

    text-align: center;
    justify-content:center
}
.modal-title{
color: #000;
font-weight: 500;
position: relative;
}
.modal-title::after{
	width: 50px;
	height: 3px;
	background-color: #2196F3;
	content: '';
	position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -7px;
}
.agree-button{
    border: none;
    color: white;
    padding: 10px 32px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    background-color: #008CBA;
    border-radius: 12px

}

.modal-lg p span{
color: #000 !important;
}
.modal-lg .modal-header .close{
    position: absolute;
    right: 15px;
    margin-top: -10px;
}
